import { message } from "antd";
import { logout } from "../api/index";

export const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

export const rem = (pixels: any) => {
  const vw_design = 375;
  return isMobile() ? `${(pixels / vw_design) * 10}rem` : `${pixels}px`;
};

export const getQuery = (variable): any => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

export const getWh = () => {
  return {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
};

export const forceLogout = async (back?: boolean) => {
  await logout(response => {
    if (response.statusCode === 200) {
      window.currentUser = null;
      back ? (window.location.href = "/") : window.location.reload();
      window.sessionStorage.removeItem("path");
    } else {
      message.error(response.body.errors);
    }
  });
};

/**
 * root是要监听的根元素，我们要对root节点内的img元素进行监听
 * 这里用到Promise.all
 */
export const onAllImgLoaded = async root => {
  const imgNodes =
    root instanceof HTMLImageElement ? [root] : root.querySelectorAll("img");
  //为了使用Array的map方法
  let imgArr = Array.prototype.slice.call(imgNodes);
  return await Promise.all(
    imgArr.map(img => {
      new Promise(resolve => {
        img.addEventListener("load", () => resolve(img));
      });
    })
  );
};
