import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import "./my-directory.scss";
import { Drawer } from "antd";
import openIcon from "../../styles/images/directory-open-icon.png";
import closeIcon from "../../styles/images/directory-close-icon.png";
import { rem } from "../../utils/tools";
import Icon from "../shared/icon/Icon";

const MyDirectory: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [directoryList, setDirectoryList] = useState<any>([]);
  const container: any = document.querySelector(".member-inbox-detail");

  const isInViewPort = (element: any) => {
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top, right, bottom, left } = element.getBoundingClientRect();

    return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight;
  };

  //去掉字符串两端的空格
  function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  }
  //去除换行
  function ClearBr(key) {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  }

  const getAlls = () =>
    Array.from(document.querySelectorAll(".member-inbox-detail-body *")).filter(
      (item: any) => !!ClearBr(trim(item.innerText))
    );

  const getSections = () =>
    Array.from(
      document.querySelectorAll(".member-inbox-detail-body section")
    ).filter((item: any) => !!ClearBr(trim(item.innerText)));

  const getH3s = () =>
    Array.from(
      document.querySelectorAll(".member-inbox-detail-body h3")
    ).filter((item: any) => !!ClearBr(trim(item.innerText)));

  const empty = getH3s().length === 0;

  const initSectionItem = (sections: any, h3s: any) => {
    if (sections.length > 0) {
      sections.forEach((item: any, index: number) => {
        item._type = "section";
        item._children = [];
        h3s.forEach((cItem: any, cIndex: number) => {
          cItem._type = "h3";
          cItem._index = cIndex;
          if (sections[index + 1]) {
            if (
              item.sort < cItem.sort &&
              sections[index + 1].sort > cItem.sort
            ) {
              cItem._parent = item;
              item._children.push(cItem);
            }
          } else {
            if (item.sort < cItem.sort) {
              cItem._parent = item;
              item._children.push(cItem);
            }
          }
        });
      });
    } else {
      h3s.forEach((cItem: any, cIndex: number) => {
        cItem._type = "h3";
        cItem._index = cIndex;
      });
    }

    return {
      sections,
      h3s,
    };
  };

  const initDirectoryList = (res: any) => {
    const { sections, h3s } = res;
    const directoryList = [
      ...sections,
      ...h3s.filter((item: any) => !item._parent),
    ].sort((a: any, b: any) => a.sort - b.sort);
    setDirectoryList(directoryList);
  };

  useEffect(() => {
    const alls = getAlls();
    alls.forEach((item: any, index: number) => (item.sort = index));
    const sections = getSections();
    const h3s = getH3s();
    const res = initSectionItem(sections, h3s);
    initDirectoryList(res);
  }, []);

  useEffect(() => {
    if (container) {
      if (open) {
        let activeArr = [];
        const nearItem: any = {
          y: Infinity,
        };
        const items = getH3s();
        items.forEach((it: any, index: number) => {
          if (isInViewPort(it)) {
            activeArr.push(index);
          }
          const res = Math.abs(container.scrollTop - it.offsetTop);

          if (res < nearItem.y) {
            nearItem.y = res;
            nearItem.index = index;
          }
        });
        let activeIndex = 0;
        if (activeArr.length > 0) {
          activeIndex = activeArr[0];
        } else {
          activeIndex = nearItem.index;
        }
        setActiveIndex(activeIndex);
      }

      if (open) {
        container.style.overflow = "hidden";
      } else {
        container.style.overflow = "unset";
        container.style.overflowY = "auto";
      }
    }
  }, [open, container]);

  const clickFn = (item: any) => {
    if (item._type === "section") {
      directoryList.forEach((listItem: any) => {
        if (listItem.innerText === item.innerText) {
          listItem._expand = !listItem._expand;
        }
      });
      setDirectoryList(directoryList.slice());
    } else {
      setOpen(false);
      const items = getH3s();
      items.forEach((it: any) => {
        if (it.innerText === item.innerText) {
          container.scrollTo({
            top: item.offsetTop - 60,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    }
  };

  return (
    <div className="my-directory">
      {!empty && (
        <div className="my-directory__entry" onClick={() => setOpen(!open)}>
          <img src={open ? closeIcon : openIcon} alt="icon" />
          目录
        </div>
      )}
      <Drawer
        title={null}
        closable={false}
        placement="right"
        width={rem(270)}
        onClose={() => setOpen(false)}
        visible={open}
        getContainer={() => document.querySelector(".my-directory")}
      >
        <div className="my-directory__body">
          <div className="my-directory__content">
            {directoryList.map((item: any, index: number) => (
              <div className={`my-directory__wrapper`} key={index}>
                <div
                  className={`my-directory__item ${
                    item._index === activeIndex
                      ? "my-directory__item--active"
                      : ""
                  } ${item._index} ${item._type}`}
                  onClick={() => clickFn(item)}
                >
                  <span className="my-directory__item--content">
                    <span>{item.innerText.replaceAll("i", "")}</span>
                    {item._type === "section" && (
                      <span className="my-directory__item--num">
                        {item._children.length}
                      </span>
                    )}
                  </span>
                  {item._type === "section" && (
                    <span className="my-directory__item--ctrl">
                      {item._expand ? (
                        <>
                          收起
                          <Icon name="pro-arrow-up" size={14} />
                        </>
                      ) : (
                        <>
                          展开
                          <Icon name="pro-arrow-down" size={16} />
                        </>
                      )}
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    item._expand
                      ? "my-directory__item--show"
                      : "my-directory__item--hide"
                  }`}
                >
                  {item._children &&
                    item._children.map((cItem: any, cIndex: number) => (
                      <div
                        key={cIndex}
                        className={`my-directory__item my-directory__cItem ${
                          cItem._index === activeIndex
                            ? "my-directory__item--active"
                            : ""
                        }`}
                        onClick={() => clickFn(cItem)}
                      >
                        {cItem.innerText}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div className="my-directory__bottom" />
        </div>
      </Drawer>
    </div>
  );
};

export default observer(MyDirectory);
